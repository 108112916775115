import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { formatHttpsUrl } from '@utils/utils';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import styles from './HeroImage.module.css';

const HeroImage = ({ heroImage, mobileHeroImage }) => {
  const [isMobile, setIsMobile] = useState(false);

  useWindowResize(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > desktop_breakpoint_extended) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
  }, [desktop_breakpoint_extended]);

  useEffect(() => {
    function setTabWistiaPlayBtn() {
      let checkWistiaPlayBtn = document.getElementsByClassName(
        'w-css-reset w-vulcan-v2-button'
      );

      // HTMLCollection does not have forEach. Can use Array.from and then do forEach.
      Array.from(checkWistiaPlayBtn).forEach((btn) => {
        btn.tabIndex = -1;
      });
    }
    window.addEventListener('load', setTabWistiaPlayBtn);
    return () => window.removeEventListener('load', setTabWistiaPlayBtn);
  }, []);

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      typeof Wistia === 'undefined' &&
      heroImage?.[0]?.mediaType === 'Video' &&
      heroImage?.[0]?.videoSource === 'Wistia'
    ) {
      const script = document.createElement('script');

      script.src = 'https://fast.wistia.com/assets/external/E-v1.js';

      document.body.appendChild(script);
    }
  }, [heroImage]);

  const renderHeroImage = () => {
    const [{ mediaType, videoSource, embeddedContent, imageAsset, altText }] =
      heroImage;
    return mediaType === 'Video' ? (
      videoSource === 'Wistia' ? (
        <div
          tabIndex="-1"
          className={`wistia_embed wistia_async_${embeddedContent} ${styles['hero-image-desktop-wrapper']} videoFoam=false fakeFullscreen=true autoPlay=true silentAutoPlay=allow muted=true endVideoBehavior=loop playbar=false playButton=false smallPlayButton=false volumeControl=false settingsControl=false share=false fullscreenButton=false fitStrategy=cover playSuspendedOffScreen=false googleAnalytics=false`}
          suppressHydrationWarning={true}
        ></div>
      ) : videoSource === 'Widen' ? (
        <div className={styles['iframe-container']}>
          <iframe
            id="widen-iframe"
            title="Clayton Homes | Widen"
            src={`https://embed.widencdn.net/video/claytonhomes/${embeddedContent}`}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
          ></iframe>
        </div>
      ) : null
    ) : (
      imageAsset && (
        <div className={styles['hero-image-desktop-wrapper']}>
          <Image
            className={styles['hero-image-desktop']}
            src={formatHttpsUrl(`${imageAsset.file?.url}?w=1920&fm=webp`)}
            alt={altText}
            priority={true}
            fill
          />
        </div>
      )
    );
  };

  const renderMobileHeroImage = () => {
    const [{ mediaType, videoSource, embeddedContent, imageAsset, altText }] =
      mobileHeroImage;

    return mediaType === 'Video' ? (
      videoSource === 'Wistia' ? (
        <div
          tabIndex="-1"
          className={`wistia_embed wistia_async_${embeddedContent} ${styles['hero-image-mobile-wrapper']} videoFoam=false fakeFullscreen=true autoPlay=true silentAutoPlay=allow muted=true endVideoBehavior=loop playbar=false playButton=false smallPlayButton=false volumeControl=false settingsControl=false share=false fullscreenButton=false fitStrategy=cover playSuspendedOffScreen=false googleAnalytics=false`}
          suppressHydrationWarning={true}
        ></div>
      ) : videoSource === 'Widen' ? (
        <div className={styles['iframe-container']}>
          <iframe
            id="widen-iframe"
            title="Clayton Homes | Widen"
            src={`https://embed.widencdn.net/video/claytonhomes/${embeddedContent}`}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
          ></iframe>
        </div>
      ) : null
    ) : (
      imageAsset && (
        <div className={styles['hero-image-mobile-wrapper']}>
          <Image
            className={styles['hero-image-mobile']}
            src={formatHttpsUrl(`${imageAsset.file?.url}?fm=webp`)}
            alt={altText}
            priority={true}
            fill
          />
        </div>
      )
    );
  };

  return isMobile && mobileHeroImage?.length > 0
    ? renderMobileHeroImage()
    : heroImage && heroImage?.length > 0
      ? renderHeroImage()
      : null;
};

export default HeroImage;

HeroImage.propTypes = {
  heroImage: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      altText: PropTypes.string,
      imageAsset: PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      embeddedContent: PropTypes.string,
      embeddedImage: PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      mediaType: PropTypes.string,
      videoSource: PropTypes.string,
    })
  ),
  mobileHeroImage: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      altText: PropTypes.string,
      imageAsset: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
};
