import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import DynamicLinkStyles from './DynamicLink.styled';
import { extractColors, getSlug, isUrlInternal } from '@utils/utils';

import Button from '@components/Shared/Buttons/Button';
import ScrollLink from '../ScrollLink/ScrollLink';
import { slugify } from '@utils/studioUtils';

const DynamicLink = ({ sectionLink }) => {
  const buttonColor = extractColors(sectionLink?.buttonAndtextColors)?.[0];
  const textColor = extractColors(sectionLink?.buttonAndtextColors)?.[1];

  const useAnchorLink =
    sectionLink?.link.charAt(0) === '#' ? slugify(sectionLink.link) : null;
  const useButton = sectionLink.displayLinkAs === 'Button';

  const rendered = () => {
    return sectionLink ? (
      <div>
        {useButton ? (
          useAnchorLink ? (
            <ScrollLink to={useAnchorLink}>
              <Button
                className="link-btn"
                navigateUrl={null}
                linkComponent={null}
              >
                {sectionLink.linkText}
              </Button>
            </ScrollLink>
          ) : (
            <Button
              className="link-btn"
              nextJSUrl={
                isUrlInternal(sectionLink.link)
                  ? getSlug(sectionLink.link)
                  : sectionLink.link
              }
              linkComponent={isUrlInternal(sectionLink.link) ? Link : null}
            >
              {sectionLink.linkText}
            </Button>
          )
        ) : useAnchorLink ? (
          <ScrollLink className="link-txt" to={useAnchorLink}>
            {sectionLink.linkText}
          </ScrollLink>
        ) : !isUrlInternal(sectionLink.link) ? (
          <a href={sectionLink.link} target="_blank" rel="noopener noreferrer">
            {sectionLink.linkText}
          </a>
        ) : (
          <Link
            href={
              isUrlInternal(sectionLink.link)
                ? getSlug(sectionLink.link)
                : sectionLink.link
            }
          >
            {sectionLink.linkText}
          </Link>
        )}
      </div>
    ) : null;
  };

  return (
    <DynamicLinkStyles $textColor={textColor} $buttonColor={buttonColor}>
      <div className="section-link">{rendered()}</div>
    </DynamicLinkStyles>
  );
};

export default DynamicLink;

DynamicLink.propTypes = {
  sectionLink: PropTypes.shape({
    linkText: PropTypes.string,
    link: PropTypes.string,
    displayLinkAs: PropTypes.string,
    buttonAndtextColors: PropTypes.string,
  }),
};
