import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

const DynamicLinkStyles = styled.div`
  .section-link {
    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: ${(props) =>
        props?.$textColor ? props.$textColor : Colors.primary.white.standard};
    }

    .link-btn {
      color: ${(props) =>
        props?.$textColor ? props.$textColor : Colors.primary.white.standard};

      background-color: ${(props) =>
        props?.$buttonColor
          ? props.$buttonColor
          : Colors.primary.claytonBlue.standard};

      margin-bottom: 18px;

      a,
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: ${(props) =>
          props?.$textColor ? props.$textColor : Colors.primary.white.standard};
      }
    }
    .link-txt {
      cursor: pointer;
    }
  }
`;

export default DynamicLinkStyles;
