export const slugify = (stringToSlugify) => {
  if (stringToSlugify && stringToSlugify.length > 0) {
    // replace mulitple spaces or whitespace with a single '-' for class name, etc.
    return stringToSlugify.replace(/\s+/g, '-').toLowerCase();
  }
};

export const isSlugInternal = (slug) => {
  slug = (slug || '').toLowerCase();

  if (slug[0] !== '/') {
    slug = '/' + slug;
  }

  return slug.indexOf('/studio') === 0;
};

// The postSubcategory field in Contentful contains the category and subcategory names
// separated by a colon (i.e. "Category name: Subcategory name").
// The postSubcategory field replaces the Subcategory field in Contentful.

export const categoryName = (postSubcategory) => {
  const names = postSubcategory ? postSubcategory.split(':') : '';
  if (names && names.length > 0) {
    return names[0].trim();
  }
  return '';
};

// Note: The 'category:' field in an article is the category name with spaces replaced by '-' (slugify) and is no longer the same as the category slug.
//    Once these were one to one (e.g. slugified category name = category slug = 'home-building'), but now the category slug may be different
//    from the category name (e.g. slugified category name = 'home-building', category slug = 'manufactured-home-building').
//
// categoryNameSlug() was added because some category slugs were requested to be changed.
//       Example:   'homeownership' to 'manufactured-homeownership'
//                  'home-building' to 'manufactured-home-building'
//
// Previously, the postSubcategory was used to derive this slug.
// Since some have changed, we must check for the changed categories and return the new one.
//
// This will return the slugified (i.e. ' ' to '-' and lower cased) category.

export const categoryNameSlug = (postSubcategory) => {
  const names = postSubcategory ? postSubcategory.split(':') : '';
  if (names && names.length > 0) {
    let categoryName = names[0].trim();
    // check for changed slugs
    if (categoryName.toLowerCase() === 'home building') {
      categoryName = 'manufactured home building';
    } else if (categoryName.toLowerCase() === 'homeownership') {
      categoryName = 'manufactured homeownership';
    }
    return slugify(categoryName);
  }
  return '';
};
