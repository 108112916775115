export const getContentTextAlignment = (contentfulString) => {
  switch (contentfulString) {
    case 'Right Align':
      return 'right';
    case 'Left Align':
      return 'left';
    case 'Center Align':
      return 'center';
    default:
      return 'left';
  }
};

export const getContentFlexAlignment = (contentfulString) => {
  switch (contentfulString) {
    case 'Right Align':
      return 'flex-end';
    case 'Left Align':
      return 'flex-start';
    case 'Center Align':
      return 'center';
    default:
      return 'flex-start';
  }
};
