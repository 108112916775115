import React from 'react';
import PropTypes from 'prop-types';
import { extractColors } from '@utils/utils';
import DynamicLink from '../DynamicLink/DynamicLink';
import Markdown from '../Markdown/Markdown';
import Colors from 'pubweb-smokey/dist/colors';
import { getContentTextAlignment } from '@utils/contentful';
import styles from './HeroSection.module.css';
import HeroImage from './HeroImage';

const getTextLocationStyle = (textLocation) => {
  switch (textLocation?.toLowerCase()) {
    case 'left side':
      return styles.left;
    case 'right side':
      return styles.right;
    case 'bottom left':
      return styles['bottom-left'];
    case 'center':
    default:
      return styles.center;
  }
};

const HeroSection = ({ heroSection }) => {
  const {
    textLocation,
    heroImage,
    mobileHeroImage,
    heroContent: {
      alignText,
      label,
      labelTextColor,
      header,
      headerTextColor,
      intro,
      introTextColor,
      introLinks,
    },
    heroBackgroundColor,
  } = heroSection;

  const hasHeroImage = heroImage?.[0] != null;

  return (
    <div
      className={styles.inner}
      style={{
        '--hero-height': hasHeroImage ? '720px' : '225px',
        '--mobile-hero-height': hasHeroImage ? '640px' : '275px',
        '--hero-text-shadow': hasHeroImage ? 'black 1px 1px 2px' : 'none',
        backgroundColor: heroBackgroundColor
          ? extractColors(heroBackgroundColor)
          : Colors.primary.claytonBlue.standard,
      }}
    >
      <HeroImage mobileHeroImage={mobileHeroImage} heroImage={heroImage} />
      <div
        className={`${styles.overlay} ${getTextLocationStyle(textLocation)}`}
      >
        {header || intro ? (
          <div
            className={styles['hero-container']}
            style={{
              '--align-text': getContentTextAlignment(alignText),
            }}
          >
            {label && (
              <div
                className={styles.label}
                style={{
                  '--label-text-color': extractColors(labelTextColor),
                }}
              >
                <Markdown markdown={label} />
              </div>
            )}
            {header && (
              <div
                className={styles.header}
                style={{
                  '--header-text-color': extractColors(headerTextColor),
                }}
              >
                <Markdown markdown={header} />
              </div>
            )}
            {intro && (
              <div
                className={styles.intro}
                style={{
                  '--intro-text-color': extractColors(introTextColor),
                }}
              >
                <Markdown markdown={intro} />
              </div>
            )}

            {introLinks?.length > 0 && (
              <div className={styles['hero-section-links']}>
                {introLinks?.map((sectionLink, i) => (
                  <DynamicLink
                    sectionLink={sectionLink}
                    key={`hero-section-link-${i + 1}`}
                  />
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeroSection;

HeroSection.propTypes = {
  heroSection: PropTypes.shape({
    heroContent: PropTypes.shape({
      alignText: PropTypes.string,
      label: PropTypes.string,
      labelTextColor: PropTypes.string,
      header: PropTypes.string,
      headerTextColor: PropTypes.string,
      intro: PropTypes.string,
      introTextColor: PropTypes.string,
      introLinks: PropTypes.arrayOf(
        PropTypes.shape({
          linkText: PropTypes.string,
          link: PropTypes.string,
          displayLinkAs: PropTypes.string,
          buttonAndtextColors: PropTypes.string,
        })
      ),
    }),
    textLocation: PropTypes.string,
    heroBackgroundColor: PropTypes.string,
    heroImage: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        altText: PropTypes.string,
        imageAsset: PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        embeddedContent: PropTypes.string,
        embeddedImage: PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        mediaType: PropTypes.string,
        videoSource: PropTypes.string,
      })
    ),
    mobileHeroImage: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        altText: PropTypes.string,
        imageAsset: PropTypes.shape({
          description: PropTypes.string,
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};
